import React, { useMemo, useState } from 'react'
import { graphql } from 'gatsby'

import StructuredData from '../components/structured-data'
import Seo from '../components/Seo'
import Layout from '../components/layout'
import parseHtml from '../components/blocks/parser'
import { AbLinkReplaceProvider } from '../components/blocks/AbLinkReplace'

// Map A/B component style testing
const pageBlockReplaceMaps: Record<string, Record<string, string>> = {
  '/products/dedicated-b/': {
    'lw/product-card': 'lw/product-card-b',
  },
  '/products/dedicated/': {
    'lw/product-card': 'lw/product-card-b',
  },
}

const PageTemplate: React.FC<PageTemplateProps> = ({ data, pageContext }) => {
  const { page } = data

  let LayoutComponent = Layout
  if (pageContext?.template?.templateName === 'Landing Page') {
    // no layout wrapper for landing pages
    LayoutComponent = React.Fragment
  }

  const post = {
    ...page,
    title: page.seo?.title ?? page.title,
    slug: page.uri.endsWith('/') ? page.uri.slice(0, -1) : page.uri,
  }

  const content = useMemo(() => {
    return parseHtml(page.contentRaw, {
      blockReplaceMap: pageBlockReplaceMaps[page.uri],
    })
  }, [page.contentRaw, page.uri])

  return (
    <LayoutComponent>
      <Seo post={post} />
      <StructuredData pageType="webPage" post={post} />
      <AbLinkReplaceProvider>
        <div className="entry">{content}</div>
      </AbLinkReplaceProvider>
    </LayoutComponent>
  )
}

type PageTemplateProps = {
  location: Record<string, unknown>
  data: {
    page: {
      title: string
      contentRaw: string
      slug: string
      uri: string
      seo: any
    }
  }
}

export const pageQuery = graphql`
  query GET_PAGE($id: String!) {
    page: wpPage(id: { eq: $id }) {
      title
      slug
      uri
      chatDepartment
      contentRaw
      date
      modified
      seo {
        ...postSeoFields
      }
      ...AdvGBRecentPostsFragment
      ...ReusableBlockFragment
    }
  }
`

export default PageTemplate
